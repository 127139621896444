import React, { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import CardBasic from "../../../components/Cards/Basic";
import PageHeading from "../../../components/PageHeading";
import * as actions from "../../../redux/actions";
import { fetchInformasi } from "../../../redux/actions/informasi";
import Collapsible from "../Collapsible";

const Informasi = (props) => {
  const { name, title, parent } = props.data;
  const { listInformasi } = useSelector((state) => state.informasi);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );
  }, [dispatch, name, parent]);

  useEffect(() => {
    dispatch(fetchInformasi({ page_size: 5, page: 1 }));
  }, [dispatch]);

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    dispatch(fetchInformasi({ page_size: 5, page: currentPage }));
  };

  return (
    <div>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <div className="">
            <PageHeading title={title} />
            <CardBasic>

              {listInformasi?.rows.map((item) => {
                if (item?.status == true) {
                  return (
                    <div key={item.public_id}>
                      <Collapsible data={item} />
                    </div>
                  );
                } else {
                  return false
                }
              })}

              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={listInformasi?.total_page}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </CardBasic>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Informasi;
