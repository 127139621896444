import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import { formatCurrency } from '../../../../../../constants/utility';
import {
  fetchBarang,
  setFormOrder,
} from '../../../../../../redux/actions/transaction';
import ModalDetailItem from '../ModalDetailItem';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { fetchInformasi } from '../../../../../../redux/actions/informasi';
import Collapsible from '../../../../../Dashboard/Collapsible';

const Item = ({ onNavigateBack }) => {
  const { barang, formOrder } = useSelector((state) => state.transaction);
  const { profile } = useSelector((state) => state.account);
  const { listInformasi } = useSelector((state) => state.informasi);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchInformasi({ page_size: 20, page: 1 }));
    dispatch(fetchBarang());
  }, [dispatch]);

  const [show, setShow] = useState(true);
  const [showInformasi, setShowInformasi] = useState(true);

  const handleOK = () => setShow(false);

  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const handleViewItemDetail = (index, e) => {
    if (e.target.type === 'button') return;
    setModalData(barang?.rows[index]);
    setIsOpen(true);
  };
  const { handleSubmit } = useForm();

  const [carts, setCarts] = useState([]);
  useEffect(() => {
    const { data } = formOrder?.steps?.find((a) => a.name === 'item');
    setCarts(data || []);
  }, [formOrder]);

  const handleQty = (item, qty) => {
    const totalbv = carts?.reduce((a, b) => a + b.qty * b.value, 0) + qty * item.value;
    let bvmax
    if (formOrder?.jenis === 'upgrade') {
      if (profile?.posisi === 'BRONZE') {
        bvmax = 3
      } else if (profile?.posisi === 'SILVER') {
        bvmax = 2
      }
    } else {
      if (formOrder?.steps?.[0]?.data?.posisi === 'BRONZE') {
        bvmax = 1
      } else if (formOrder?.steps?.[0]?.data?.posisi === 'SILVER') {
        bvmax = 2
      } else if (formOrder?.steps?.[0]?.data?.posisi === 'PLATINUM') {
        bvmax = 4
      }
    }

    if (totalbv > bvmax && bvmax > 0) return;
    if (qty === 0) {
      const tmpCarts = carts?.filter((a) => a.public_id !== item.public_id, []);
      setCarts(tmpCarts);
    } else {
      const check = carts?.find((a) => a.public_id === item.public_id);
      if (!check) {
        setCarts([
          ...carts,
          {
            public_id: item.public_id,
            nama: item.nama,
            harga: item.harga,
            value: item.value,
            berat: item.berat,
            dpp: item.dpp,
            persen_ppn: item.persen_ppn,
            qty: qty,
          },
        ]);
      } else {
        const tmpCarts = carts
          ?.map((a) => {
            if (a.public_id === item.public_id) {
              return {
                ...a,
                qty: a.qty + qty,
              };
            }
            return a;
          }, [])
          .filter((filter) => filter.qty > 0, []);

        setCarts(tmpCarts);
      }
    }
  };

  useEffect(() => { }, [carts]);

  const minumumValue =
    formOrder?.jenis === 'registrasi'
      ? barang?.setting?.[
      formOrder?.steps?.find((a) => a.name === 'member')?.data?.posisi
      ] || 0
      : 0;

  const onNext = () => {
    const tmpData = {
      ...formOrder,
      activeIndex: formOrder.activeIndex + 1,
      activeStep: formOrder.steps.find(
        (a, i) => i === formOrder.activeIndex + 1
      )?.name,
      steps: formOrder.steps?.map((a) => {
        if (a.name === 'item') {
          return {
            ...a,
            data: carts.filter((a) => a.qty > 0, []),
            passed: true,
          };
        }
        return a;
      }, []),
    };
    dispatch(setFormOrder(tmpData));
  };

  const [disableMax, setDisambleMax] = useState(false);
  useEffect(() => {
    const totalbv = carts?.reduce((a, b) => a + b.qty * b.value, 0);
    let bvmax
    if (formOrder?.jenis === 'upgrade') {
      if (profile?.posisi === 'BRONZE') {
        bvmax = 3
      } else if (profile?.posisi === 'SILVER') {
        bvmax = 2
      }
    } else {
      if (formOrder?.steps?.[0]?.data?.posisi === 'BRONZE') {
        bvmax = 1
      } else if (formOrder?.steps?.[0]?.data?.posisi === 'SILVER') {
        bvmax = 2
      } else if (formOrder?.steps?.[0]?.data?.posisi === 'PLATINUM') {
        bvmax = 4
      }
    }
    setDisambleMax(totalbv >= bvmax && bvmax > 0);
  }, [carts]);

  const informasi_menu_upg = listInformasi?.rows?.find((f) => f.menu_upg == true)

  return (
    <>
      <h5 className='border-bottom py-2 mb-4' style={{ color: '#3d405c' }}>
        Pilih Paket/Item
      </h5>
      {formOrder?.jenis === 'registrasi' && (
        <div className='alert alert-info'>
          Ketentuan minimal pembelian untuk Registrasi :{' '}
          <strong>PLATINUM {barang?.setting?.PLATINUM} BV</strong>,{' '}
          <strong>SILVER {barang?.setting?.SILVER} BV</strong>,{' '}
          <strong>BRONZE {barang?.setting?.BRONZE} BV</strong>
          <div>
            Bonus yang dihitung dari pembelian adalah{' '}
            <strong>produk ke-1 </strong>sesuai ketentuan minimal pembelian
          </div>
          <Modal show={show}>
            <Modal.Header style={{ background: '#52567a' }}>
              <Modal.Title style={{ color: 'white' }}>
                <div style={{ fontSize: '20px' }}>
                  <i className='fa fa-info mr-3'> </i>
                  <strong>Pesan Informasi</strong>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ color: 'grey', fontSize: '16px' }}>
                Sponsor: {profile?.id_member} - {profile?.nama}
              </div>
              <div style={{ color: 'grey', fontSize: '16px' }}>
                Tipe: {profile?.posisi}
              </div>

              <hr />

              <div style={{ color: 'grey', fontSize: '16px' }}>
                Member: {formOrder?.steps?.[0]?.data?.nama}
              </div>
              <div style={{ color: 'grey', fontSize: '16px' }}>
                Sisi: {formOrder?.steps?.[0]?.data?.sisi} &nbsp;
                {formOrder?.steps?.[0]?.data?.sisi === 'R'
                  ? '[KANAN]'
                  : '[KIRI]'}
              </div>
              <div style={{ color: 'grey', fontSize: '16px' }}>
                Tipe: {formOrder?.steps?.[0]?.data?.posisi}
              </div>
              <div className='mt-5' style={{ color: 'grey', fontSize: '14px' }}>
                Harap konfirmasi SPONSOR & SISI untuk melanjutkan pendaftaran.
                Perhatikan bahwa: Tidak ada lagi perubahan pada sponsor & sisi
                jika Anda mengklik [OK] untuk melanjutkan
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={onNavigateBack}>
                Batal
              </Button>
              <Button variant='primary' onClick={handleOK}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      {formOrder?.jenis === 'upgrade' && (
        <>
          <div className='alert alert-info'>
            Ketentuan untuk Uprade ke PLATINUM minimal{' '}
            {barang?.setting?.PLATINUM - barang?.setting?.SILVER} BV
            <div>
              Bonus yang dihitung dari pembelian adalah{' '}
              <strong>produk ke-1 </strong>sesuai ketentuan minimal pembelian
            </div>
          </div>
        </>
      )}
      {formOrder?.jenis === 'registrasi' && (
        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-4'>
          {barang?.rows
            ?.filter((a) => a.value > 0, [])
            ?.map((item, index) => {
              const cart = carts?.find((a) => a.public_id === item.public_id);
              return (
                <div className='col mb-4' key={item.public_id}>
                  <div className='card custom-card d-flex'>
                    {item.gambars[0]?.lokasi ? (
                      <img
                        className='card-img-top'
                        src={item.gambars[0]?.lokasi}
                        alt='Card cap'
                        onClick={(e) => handleViewItemDetail(index, e)}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <Skeleton height={150} />
                    )}
                    <div className='card-body' style={{ cursor: 'pointer' }}>
                      <h6 className='card-title text-two-line'>{item.nama}</h6>
                      <div className='d-md-flex mb-3 mt-2 justify-content-between d-sm-block'>
                        <div className='card-title font-weight-bold py-1'>
                          {formatCurrency(item.harga)}
                        </div>
                        <div className='d-flex'>
                          <button
                            style={{ width: '25px', height: '30px' }}
                            type='button'
                            className='btn btn-sm btn-primary-f flex'
                            onClick={() => handleQty(item, -1)}
                            disabled={!cart || cart?.qty < 1}
                          >
                            -
                          </button>
                          <span
                            className='border'
                            style={{
                              width: '35px',
                              height: '30px',
                              textAlign: 'center',
                              padding: '2px',
                              borderRadius: '2px',
                              marginRight: '1px',
                              marginLeft: '1px',
                            }}
                          >
                            {cart?.qty || 0}
                          </span>
                          <button
                            style={{ width: '25px', height: '30px' }}
                            type='button'
                            className='btn btn-sm btn-primary-f'
                            onClick={() => handleQty(item, 1)}
                            disabled={disableMax}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <span className='badge badge-info badge-phill py-1 px-2'>
                        {item.value} BV
                      </span>
                      <div className=''>{item.deskripsi}</div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {formOrder?.jenis === 'upgrade' && (
        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-4'>
          {
            informasi_menu_upg && (
              <Modal show={showInformasi} size="lg">
                <Modal.Header style={{ background: '#52567a' }}>
                  <Modal.Title style={{ color: 'white' }}>
                    <div style={{ fontSize: '20px' }}>
                      <i className='fa fa-info mr-3'> </i>
                      <strong>{informasi_menu_upg?.title}</strong>
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Collapsible data={informasi_menu_upg} />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={() => setShowInformasi(false)}>
                    Tutup
                  </Button>
                </Modal.Footer>
              </Modal>
            )
          }
          {barang?.rows
            ?.filter((a) => a.value > 0 && a.value < 4, [])
            ?.map((item, index) => {
              const cart = carts?.find((a) => a.public_id === item.public_id);
              return (
                <div className='col mb-4' key={item.public_id}>
                  <div className='card custom-card d-flex'>
                    {item.gambars[0]?.lokasi ? (
                      <img
                        className='card-img-top'
                        src={item.gambars[0]?.lokasi}
                        alt='Card cap'
                        onClick={(e) => handleViewItemDetail(index, e)}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <Skeleton height={150} />
                    )}
                    <div className='card-body' style={{ cursor: 'pointer' }}>
                      <h6 className='card-title text-two-line'>{item.nama}</h6>
                      <div className='d-md-flex mb-3 mt-2 justify-content-between d-sm-block'>
                        <div className='card-title font-weight-bold py-1'>
                          {formatCurrency(item.harga)}
                        </div>
                        <div className='d-flex'>
                          <button
                            style={{ width: '25px', height: '30px' }}
                            type='button'
                            className='btn btn-sm btn-primary-f flex'
                            onClick={() => handleQty(item, -1)}
                            disabled={!cart || cart?.qty < 1}
                          >
                            -
                          </button>
                          <span
                            className='border'
                            style={{
                              width: '35px',
                              height: '30px',
                              textAlign: 'center',
                              padding: '2px',
                              borderRadius: '2px',
                              marginRight: '1px',
                              marginLeft: '1px',
                            }}
                          >
                            {cart?.qty || 0}
                          </span>
                          <button
                            style={{ width: '25px', height: '30px' }}
                            type='button'
                            className='btn btn-sm btn-primary-f'
                            onClick={() => handleQty(item, 1)}
                            disabled={disableMax}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <span className='badge badge-info badge-phill py-1 px-2'>
                        {item.value} BV
                      </span>
                      <div className=''>{item.deskripsi}</div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}

      {formOrder?.jenis === 'repeat-order' && (
        <div>
          <h5 className='border-bottom'>Pilih Repeat Order</h5>
          <p>Pembelian Repeat Order Tidak Dapat Membuat Member Baru</p>
          <div className='row row-cols-1 row-cols-sm-2 row-cols-md-4'>
            {barang?.rows
              ?.map((item, index) => {
                const cart = carts?.find((a) => a.public_id === item.public_id);
                return (
                  <div className='col mb-4' key={item.public_id}>
                    <div className='card custom-card d-flex'>
                      {item.gambars[0]?.lokasi ? (
                        <img
                          className='card-img-top'
                          src={item.gambars[0]?.lokasi}
                          alt='Card cap'
                          onClick={(e) => handleViewItemDetail(index, e)}
                          style={{ cursor: 'pointer' }}
                        />
                      ) : (
                        <Skeleton height={150} />
                      )}
                      <div className='card-body' style={{ cursor: 'pointer' }}>
                        <h6 className='card-title text-two-line'>
                          {item.nama}
                        </h6>
                        <div className='d-md-flex mb-3 mt-2 justify-content-between d-sm-block'>
                          <div className='card-title font-weight-bold py-1'>
                            {formatCurrency(item.harga)}
                          </div>
                          <div className='d-flex'>
                            <button
                              style={{ width: '25px', height: '30px' }}
                              type='button'
                              className='btn btn-sm btn-primary-f flex'
                              onClick={() => handleQty(item, -1)}
                              disabled={!cart || cart?.qty < 1}
                            >
                              -
                            </button>
                            <span
                              className='border'
                              style={{
                                width: '35px',
                                height: '30px',
                                textAlign: 'center',
                                padding: '2px',
                                borderRadius: '2px',
                                marginRight: '1px',
                                marginLeft: '1px',
                              }}
                            >
                              {cart?.qty || 0}
                            </span>
                            <button
                              style={{ width: '25px', height: '30px' }}
                              type='button'
                              className='btn btn-sm btn-primary-f'
                              onClick={() => handleQty(item, 1)}
                              disabled={disableMax}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className=''>{item.deskripsi}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}

      <table className='table' style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>Paket</th>
            <th className='text-right'>Qty</th>
            <th className='text-right'>BV</th>
            <th className='text-right'>Harga</th>
          </tr>
        </thead>
        <tbody>
          {carts?.map((item, key) => {
            return (
              <tr key={key}>
                <td>{item.nama}</td>
                <td className='text-right'>{item.qty}</td>
                <td className='text-right'>{item.qty * item.value}</td>
                <td className='text-right'>
                  {formatCurrency(item.harga * item.qty)}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th className='text-right'>Total</th>
            <th className='text-right'>
              {carts?.reduce((a, b) => a + b.qty, 0)}
            </th>
            <th className='text-right'>
              {carts?.reduce((a, b) => a + b.qty * b.value, 0)}
            </th>
            <th className='text-right'>
              {formatCurrency(carts?.reduce((a, b) => a + b.qty * b.harga, 0))}
            </th>
          </tr>
        </tfoot>
      </table>
      <hr />
      <div className='stepper-navigation'>
        <div className='d-flex justify-content-center'>
          {formOrder?.jenis === 'registrasi' && (
            <button
              type='button'
              className='btn btn-outline-secondary btn-md font-weight-bold mr-1 px-10 flex-grow-1'
              onClick={() => onNavigateBack()}
            >
              <i className='fas fa-chevron-left mr-4'></i>
              Prev
            </button>
          )}
          <button
            onClick={() => onNext()}
            disabled={
              !carts ||
                carts?.length === 0 ||
                carts?.reduce((a, b) => a + b.qty * b.value, 0) < minumumValue
                ||
                profile?.posisi === 'BRONZE' && carts?.reduce((a, b) => a + b.qty * b.value, 0) === 2 && formOrder?.jenis === 'upgrade' ? true : false ||
                  profile?.posisi === 'SILVER' && carts?.reduce((a, b) => a + b.qty * b.value, 0) === 1 && formOrder?.jenis === 'upgrade' ? true : false
            }
            type='submit'
            className='btn btn-primary-f btn-md font-weight-bold ml-1 px-10 flex-grow-1'
          >
            Next
            <i className='fas fa-chevron-right ml-4'></i>
          </button>
        </div>
      </div>

      <ModalDetailItem
        data={modalData}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleSubmit={handleSubmit}
        submit={(data) => console.log(data)}
      />
    </>
  );
};

export default Item;
