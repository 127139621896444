import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TextInput from "../../../../../../components/TextInput";
import UsernameWithSugestion from "../../../../../../components/UsernameWithSugestion";
import PhoneInput, {
  formatPhone,
} from "../../../../../../components/PhoneInput";
import {
  arr_kabupaten,
  arr_kecamatan,
  arr_propinsi,
} from "../../../../../../assets/js/alamat";
import { useDispatch, useSelector } from "react-redux";
import { setFormOrder } from "../../../../../../redux/actions/transaction";
import axios from "axios";
import { fetchInformasi } from '../../../../../../redux/actions/informasi';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Collapsible from "../../../../../Dashboard/Collapsible";

const sisiOpt = [
  { value: "R", label: "Kanan" },
  { value: "L", label: "Kiri" },
];

// Update 2024
const posisiOpt = [
  { value: "PLATINUM", label: "PLATINUM" },
  { value: "SILVER", label: "SILVER" },
  { value: "BRONZE", label: "BRONZE" },
];

const FormMember = () => {
  const { formOrder } = useSelector((state) => state.transaction);
  const { data } = useSelector((state) => state.auth);
  const { listInformasi } = useSelector((state) => state.informasi);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    dispatch(fetchInformasi({ page_size: 20, page: 1 }));
    const { data } = formOrder?.steps?.find((a) => a.name === "member");
    const posisi = posisiOpt.find((a) => a.value === data?.posisi);
    const sisi = sisiOpt.find((a) => a.value === data?.sisi);


    setValue("posisi", posisi?.value || "");
    setValue("sisi", sisi?.value || "");
    setValue("nama", data?.nama || "");
    setValue("email", data?.email || "");
    setValue("noIdentitas", data?.noIdentitas || "");
    setValue("tglLahir", data?.tglLahir || "");
    setValue("jenisKelamin", data?.jenisKelamin || "LAKI-LAKI");
    setValue("username", data?.username || "");
    setValue("agama", data?.agama || "");
    setValue("phoneNumber", formatPhone(data?.phoneNumber) || "62");
    setValue("statusKawin", data?.statusKawin || "");
    setValue("alamat", data?.alamat || "");
    setValue("idKota", data?.idKota || "");
    setValue("idKecamatan", data?.idKecamatan || "");
    setValue("idPropinsi", data?.idPropinsi || "");
    setValue("negara", data?.negara || "Indonesia");
    setValue("kodePos", data?.kodePos || "");
    setValue("idPropinsiDomisili", data?.idPropinsiDomisili || "");
    setValue("idKotaDomisili", data?.idKotaDomisili || "");
  }, [formOrder, setValue]);

  const [optKabupaten, setOptKabupaten] = useState([]);
  const [optKecamatan, setOptKecamatan] = useState([]);
  const watchPropinsi = watch("idPropinsi");
  const watchKota = watch("idKota");

  const [optKabupatenDomisili, setOptKabupatenDomisili] = useState([]);
  const watchPropinsiDomisili = watch("idPropinsiDomisili");

  const [show, setShow] = useState(true);


  useEffect(() => {
    const key = parseInt(watchPropinsi);
    if (key > 0) {
      var newArray = arr_kabupaten.filter(function (e) {
        return e.prop === key;
      });
      setOptKabupaten(newArray);
      setValue("idKecamatan", null);
      setValue("idKota", null);
    }
  }, [watchPropinsi, setValue]);

  useEffect(() => {
    const key = parseInt(watchPropinsiDomisili);
    if (key > 0) {
      var newArray = arr_kabupaten.filter(function (e) {
        return e.prop === key;
      });
      setOptKabupatenDomisili(newArray);
      setValue("idKotaDomisili", null);
    }
  }, [watchPropinsiDomisili, setValue]);

  useEffect(() => {
    const key = parseInt(watchKota);
    if (key > 0) {
      var newArray = arr_kecamatan.filter(function (e) {
        return e.kab === key;
      });
      setOptKecamatan(newArray);
    }
  }, [watchKota]);

  useEffect(() => {
    const { data } = formOrder?.steps?.find((a) => a.name === "member");
    setValue("idKota", data?.idKota);
  }, [formOrder, optKabupaten, setValue]);

  useEffect(() => {
    const { data } = formOrder?.steps?.find((a) => a.name === "member");
    setValue("idKotaDomisili", data?.idKotaDomisili);
  }, [formOrder, optKabupatenDomisili, setValue]);

  useEffect(() => {
    const { data } = formOrder?.steps?.find((a) => a.name === "member");
    setValue("idKecamatan", data?.idKecamatan);
  }, [formOrder, optKecamatan, setValue]);

  const submitRegister = (data) => {
    const data2 = {
      alamat: data.alamat,
      email: data.email,
      idKecamatan: data.idKecamatan,
      idKota: data.idKota,
      idPropinsi: data.idPropinsi,
      jenisKelamin: data.jenisKelamin,
      kodePos: data.kodePos,
      nama: data.nama,
      negara: data.negara,
      noIdentitas: data.noIdentitas,
      phoneNumber: data.phoneNumber?.replaceAll(" ", ""),
      posisi: data.posisi,
      sisi: data.sisi,
      agama: "",
      statusKawin: "",
      tglLahir: data.tglLahir,
      username: data.username,
      idKotaDomisili: data.idKotaDomisili,
      idPropinsiDomisili: data.idPropinsiDomisili,
    };

    const tmpData = {
      ...formOrder,
      activeIndex: formOrder.activeIndex + 1,
      activeStep: formOrder.steps.find(
        (a, i) => i === formOrder.activeIndex + 1
      )?.name,
      steps: formOrder.steps?.map((a) => {
        if (a.name === "member") {
          return {
            ...a,
            data: data2,
            passed: true,
          };
        }
        if (a.name === "item") {
          return {
            ...a,
            data: [],
          };
        }
        return a;
      }, []),
    };
    dispatch(setFormOrder(tmpData));
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const informasi_menu_reg = listInformasi?.rows?.find((f) => f.menu_reg == true)

  return (
    <div className="px-2">
      <h5 className="border-bottom py-2 mb-4" style={{ color: "#3d405c" }}>
        Lengkapi data member
      </h5>
      {
        informasi_menu_reg && (
          <Modal show={show} size="lg">
            <Modal.Header style={{ background: '#52567a' }}>
              <Modal.Title style={{ color: 'white' }}>
                <div style={{ fontSize: '20px' }}>
                  <i className='fa fa-info mr-3'> </i>
                  <strong>{informasi_menu_reg?.title}</strong>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Collapsible data={informasi_menu_reg} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setShow(false)}>
                Tutup
              </Button>
            </Modal.Footer>
          </Modal>
        )
      }

      <form onSubmit={handleSubmit(submitRegister)}>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <TextInput
              layout="vertical"
              label="No Identitas KTP/SIM"
              type="text"
              name="noIdentitas"
              placeholder="Nomor Identitas KTP/SIM"
              register={register}
              errors={errors}
              validations={{
                validate: (value) => {
                  const val = value.toString().replaceAll(" ", "");
                  if (val.length !== 16) return "Nomor harus diisi 16 digit";
                  return true;
                },
                onChange: (e) => {
                  let val = e.target.value
                    .toString()
                    .replace(/\D/g, "")
                    .replaceAll(" ", "");
                  e.target.value = val;
                },
              }}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <TextInput
              layout="vertical"
              label="Nama Lengkap"
              type="text"
              name="nama"
              placeholder="Nama Lengkap"
              register={register}
              errors={errors}
              validations={{
                required: "Masukkan nama lengkap",
                validate: (value) => {
                  const regex = /^[a-zA-Z\s]*$/;
                  if (!regex.test(value)) {
                    return 'Nama lengkap hanya boleh terdiri dari huruf dan spasi';
                  }
                  if (value.length > 60) {
                    return 'Nama lengkap tidak boleh melebihi 60 karakter';
                  }
                },
                onChange: (e) => {
                  let val = e.target.value.toString()
                  while (val.startsWith(' ')) {
                    val = val.slice(1);
                  }
                  e.target.value = val;
                }
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <TextInput
              layout="vertical"
              label={`Alamat Email`}
              type="email"
              name="email"
              placeholder="Alamat Email"
              register={register}
              errors={errors}
              validations={{
                required: "Masukkan email",
                validate: async (value) => {
                  const pattern =
                    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,6})$/;
                  if (!value.match(pattern)) return "Format email tidak valid";

                  // axios call
                  const isValid = await axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_API_URL}/member/mail/check`,
                    data: { email: value },
                    headers: {
                      Authorization: `Bearer ${data?.access_token}`,
                    },
                  })
                    .then(() => true)
                    .catch(() => false);
                  if (!isValid) return "Email tidak valid";

                  return true;
                },
              }}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <PhoneInput
              label="Nomor Whatsapp"
              name="phoneNumber"
              register={register}
              errors={errors}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <UsernameWithSugestion
              label="Username"
              placeholder="Username"
              name="username"
              fullName={watch("nama")}
              register={register}
              errors={errors}
              onSet={(value) => setValue("username", value?.toLowerCase())}
              onValidate={(value) => {
                console.log("Validate", value)
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <TextInput
              layout="vertical"
              label="Tipe Calon Member"
              type="select"
              name="posisi"
              placeholder=""
              register={register}
              errors={errors}
              validations={{ required: "Pilih Tipe member " }}
              options={posisiOpt}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <TextInput
              layout="vertical"
              label="Sisi"
              type="select"
              name="sisi"
              placeholder=""
              register={register}
              errors={errors}
              validations={{ required: "Pilih Sisi " }}
              options={sisiOpt}
            />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <TextInput
              layout="vertical"
              label="Tanggal Lahir"
              type="date"
              name="tglLahir"
              placeholder="Tanggal Lahir"
              register={register}
              errors={errors}
              validations={{
                required: "Masukkan tanggal lahir",
                validate: (value) => {
                  const birthDate = new Date(value);
                  const currentDate = new Date();
                  const minAgeDate = new Date(
                    currentDate.getFullYear() - 17,
                    currentDate.getMonth(),
                    currentDate.getDate() + 1
                  );

                  if (birthDate > minAgeDate) {
                    return "Anda harus berusia minimal 17 tahun";
                  }

                  return true;
                },
              }}
            />
          </div>
          {/* <div className="col-sm-12 col-md-6">
            <TextInput
              layout="vertical"
              label="Agama"
              type="select"
              name="agama"
              placeholder="Agama"
              register={register}
              errors={errors}
              validations={{ required: "Pilih agama" }}
              options={[
                { value: "ISLAM", label: "ISLAM" },
                { value: "KATOLIK", label: "KATOLIK" },
                { value: "PROTESTAN", label: "KRISTEN / PROTESTAN" },
                { value: "HINDU", label: "HINDU" },
                { value: "BUDHA", label: "BUDHA" },
                { value: "KONGHUCU", label: "KONGHUCU" },
                { value: "LAINNYA", label: "LAINNYA" },
              ]}
            />
          </div> */}
          {/* <div className="col-sm-12 col-md-6">
            <TextInput
              layout="vertical"
              label="Status Pernikahan"
              type="select"
              name="statusKawin"
              placeholder=""
              register={register}
              errors={errors}
              validations={{ required: "Pilih status " }}
              options={[
                { value: "BELUM KAWIN", label: "BELUM MENIKAH" },
                { value: "KAWIN", label: "SUDAH MENIKAH" },
                { value: "BERCERAI", label: "BERCERAI" },
                { value: "LAINNYA", label: "LAINNYA" },
              ]}
            />
          </div> */}
          <div className="col-sm-12 col-md-6">
            <TextInput
              layout="vertical"
              label="Jenis Kelamin"
              type="radio"
              name="jenisKelamin"
              placeholder="Pria/Wanita"
              defaultValue={watch("jenisKelamin")}
              register={register}
              errors={errors}
              options={[
                { value: "LAKI-LAKI", label: "PRIA" },
                { value: "PEREMPUAN", label: "WANITA" },
              ]}
            />
          </div>
          <div className="col-sm-12">
            <hr />
          </div>
          <div className="col-sm-12 col-md-6">
            <TextInput
              layout="vertical"
              label="Propinsi"
              type="select"
              name="idPropinsi"
              placeholder=""
              register={register}
              errors={errors}
              validations={{ required: "Pilih propinsi" }}
              options={arr_propinsi}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <TextInput
              layout="vertical"
              label="Kab/Kota"
              type="select"
              name="idKota"
              placeholder=""
              register={register}
              validations={{ required: "Pilih kabupaten / kota" }}
              errors={errors}
              options={optKabupaten}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <TextInput
              layout="vertical"
              label="Kecamatan"
              type="select"
              name="idKecamatan"
              placeholder=""
              register={register}
              errors={errors}
              validations={{ required: "Pilih  kecamatan" }}
              options={optKecamatan}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <TextInput
              layout="vertical"
              label="Kode Pos"
              type="text"
              name="kodePos"
              placeholder=""
              register={register}
              errors={errors}
              validations={{
                required: "Masukkan kode pos",
                pattern: {
                  value: /^\d{5}$/,
                  message: "Kode pos harus terdiri dari 5 digit angka",
                },
              }}
            />
          </div>
          <div className="col-sm-12">
            <TextInput
              layout="vertical"
              label="Alamat Lengkap"
              type="textarea"
              name="alamat"
              placeholder="Masukkan Alamat Lengkap Anda"
              register={register}
              errors={errors}
              validations={{ required: "Masukkan  alamat" }}
            />
          </div>
          {/* domisili */}
          <div className="col-sm-12 col-md-6">
            <TextInput
              layout="vertical"
              label="Propinsi Domisili"
              type="select"
              name="idPropinsiDomisili"
              placeholder=""
              register={register}
              errors={errors}
              validations={{ required: "Pilih propinsi domisili" }}
              options={arr_propinsi}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <TextInput
              layout="vertical"
              label="Kab/Kota Domisili"
              type="select"
              name="idKotaDomisili"
              placeholder=""
              register={register}
              validations={{ required: "Pilih kabupaten / kota domisili" }}
              errors={errors}
              options={optKabupatenDomisili}
            />
          </div>
          <div className="col-sm-12 pt-3 pb-2">
            <h6>
              <strong>Terms Of Conditions</strong>
            </h6>
            <input
              className="mr-2"
              type="checkbox"
              checked={isChecked}
              onChange={handleOnChange}
            />{" "}
            Saya telah membaca dan Saya menyetujui
            <a
              className="ml-2 mr-2"
              target="_blank"
              href="https://thefavored-one.com/kode-etik"
            >
              Kode Etik
            </a>
            yang telah ditetapkan TheFavored One
          </div>
        </div>
        <div className="stepper-navigation">
          <div className="d-flex justify-content-center">
            <button
              disabled={isChecked ? false : true}
              type="submit"
              className="btn btn-primary-f btn-md font-weight-bold ml-1 px-10 w-100"
            >
              Confirm
              <i className="fas fa-chevron-right ml-4"></i>
            </button>
          </div>
        </div>
      </form >
    </div >
  );
};

export default FormMember;
